import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import usePortfolioSectionStyles from './styles';
import PortfolioItem from './PortfolioItem/PortfolioItem';
import portfolioData from './portfolioData';

const PortfolioSection = () => {
  const classes = usePortfolioSectionStyles();

  return (
    <section id="portfolio-section">
      <svg
        className={classes.svg}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="100"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <path d="M0 100 C 20 0 50 0 100 100 Z" />
      </svg>
      <Grid
        container
        alignItems="center"
        direction="column"
        className={classes.portfolioContainer}
      >
        <Typography variant="h2" className={classes.portfolioTitle}>
          Portfolio
        </Typography>
        <Grid item lg={10} md={10} sm={10} xs={10}>
          <Grid
            container
            alignItems="center"
            justify="center"
          >
            {
              portfolioData.map((item, idx) => (
                <PortfolioItem
                  text={item.text}
                  imageSrc={item.imageSrc}
                  linkHref={item.linkHref}
                  key={idx}
                />
              ))
            }
          </Grid>
        </Grid>
      </Grid>
      <svg
        className={classes.svg}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="100"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <path d="M0 0 C 50 100 80 100 100 0 Z" />
      </svg>
    </section>
  );
};
export default PortfolioSection;
