import React, { memo } from 'react';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import { Typography, Grid } from '@material-ui/core';
import photo from './my-photo.jpg';
import useAboutSectionStyles from './style';

const AboutSection = () => {
  const classes = useAboutSectionStyles();
  return (
    <section id="about-section">
      <Grid
        container
        alignItems="center"
        direction="column"
        className={`${classes.root} ${classes.verticalSpacing}`}
      >
        <Grid item xs={10} md={8} lg={8}>
          <Typography variant="h2">
            About Me
          </Typography>
        </Grid>

        <Grid item md={10} lg={8}>
          <Grid container className={classes.verticalSpacing}
            justify="space-evenly"
            alignItems="center">
            <Grid item xs={10} md={6} lg={6}>
              <Grid container
                alignItems="center"
                direction="column"
                alignContent="center">
                <LaptopMacIcon fontSize="inherit" className={classes.laptopIcon} />
                <p className={classes.bio}>
                  Just started the third year of my Bachelor of Computer
                  Science degree at New Bulgarian University. Interested in participating in
                  various projects and trying new technologies. The purpose of this website is
                   to showcase some of my work for everyone to see. I have some experience with
                    Java as a general - purpose language, as well as Javascript/HTML/CSS/Bootstrap
                     for the front - end programming.
                </p>
              </Grid>
            </Grid>

            <Grid item xs={10} md={6} lg={6}>
              <Grid justify="center" container>
                <img alt={'Vasil Pavlov\'s profile'} id="my-photo" src={photo} className={classes.photo} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
};

export default memo(AboutSection);
