import Fitness from './images/fitness.jpg';
import QuoteGenerator from './images/random-quote.jpg';
import Converter from './images/docx-to-xlsx-converter.jpg';
import Shopify from './images/shopify-experts.png'

const porfolioData = [
  {
    text: 'A Sample Fitness Website',
    imageSrc: Fitness,
    linkHref: 'https://vasilpavlov.com/fitness/fitness.html'    
  },
  {
    text: 'A Random Quote Generator',
    imageSrc: QuoteGenerator,
    linkHref: 'https://vasilpavlov.com/random-quote/random-quote.html'
  },
  {
    text: 'Java "docx" to "xlsx" converter',
    imageSrc: Converter,
    linkHref: 'https://github.com/PavlovVasil/docx-to-xlsx-converter'
  },
  {
    text: 'Shopify Experts Python Scraper',
    imageSrc: Shopify,
    linkHref: 'https://github.com/PavlovVasil/puppeteer-shopify'
  },
];
export default porfolioData;
