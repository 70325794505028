import { makeStyles } from '@material-ui/core/styles';

const useAboutSectionStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary
  },
  laptopIcon: {
    fontSize: '10rem',
    color: '#f37937',
  },
  photo: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  verticalSpacing: {
    marginTop: theme.spacing(16),
  },
  bio: {
    fontSize: '1.2rem',
    padding: theme.spacing(2),
  },
}));

export default useAboutSectionStyles;
