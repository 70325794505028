import { makeStyles } from '@material-ui/core/styles';

const useTopNavStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    marginLeft: '8px!important',
  }
}));

export default useTopNavStyles;
