import { makeStyles } from '@material-ui/core/styles';

const usePortfolioItemStyles = makeStyles((theme) => ({
  title: {
    padding: '8px 0px',
    fontSize: 16,
    textTransform: 'uppercase',
  },
  portfolioItem: {
    backgroundColor: '#f37937',
    transition: 'all 0.35s ease-in-out',
    margin: '16px',
    textAlign: 'center',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    '&:hover': {
      boxShadow: '0 3px 8px rgba(0,0,0,0.30), 0 3px 8px rgba(0,0,0,0.35)',
    },
    '& a': {
      transition: 'all 0.35s ease-in-out',
      color: theme.palette.text.primary,
      textDecoration: 'none !important',
      '&:hover': {
        color: '#ffffff',
      },
    },
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

export default usePortfolioItemStyles;
