import { makeStyles } from '@material-ui/core/styles';

const useFooterSectionStyles = makeStyles((theme) => ({
  footer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    background: '#f37937',
    textAlign: 'center',
    color: '#ffffff',
    marginBottom: 'unset',
  },
}));

export default useFooterSectionStyles;
