import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import TwitterIcon from '@material-ui/icons/Twitter';
import useContactSectionStyles from './style';

const ContactSection = () => {
  const classes = useContactSectionStyles();
  return (
    <section id="contact-section">
      <Grid container alignItems="center" direction="column">
        <Typography variant="h2" className={classes.contactTitle}>
          Contact
        </Typography>
        
        <Grid
          container
          className={classes.socialMediaIconContainer}
          justify="center"
          // For some reason putting this container in an item with lg={8} breaks the layout, so I am putting
          // width set in percentage
          style={{width: '60%'}}
        >
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Link href="https://www.linkedin.com/in/pavlov-vasil/">
              <LinkedInIcon />
            </Link>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Link href="https://github.com/PavlovVasil">
              <GitHubIcon />
            </Link>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Link href="https://twitter.com/Vasil_Pavlov">
              <TwitterIcon />
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
};

export default ContactSection;
