import { makeStyles } from '@material-ui/core/styles';

const usePortfolioSectionStyles = makeStyles((theme) => ({
  svg: {
    fill: '#fdc800',
    stroke: '#fdc800',
    marginBottom: '-4px',
  },
  portfolioContainer: {
    background: '#fdc800',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  portfolioTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export default usePortfolioSectionStyles;
