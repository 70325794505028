import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#FFE0B2',
      main: '#FF9800',
      dark: '#F57C00',
      contrastText: '#212121',
    },
    // secondary: {
    //   light: palette.secondary.A200,
    //   main: palette.secondary.A400,
    //   dark: palette.secondary.A700,
    //   contrastText: getContrastText(palette.secondary.A400),
    // },
    // error: {
    //   light: palette.error[300],
    //   main: palette.error[500],
    //   dark: palette.error[700],
    //   contrastText: getContrastText(palette.error[500]),
    // },
    text: {
      primary: '#333333',
      secondary: '#757575',
    },
  },
});

export default theme;
