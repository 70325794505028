import { makeStyles } from '@material-ui/core/styles';

const useContactSectionStyles = makeStyles((theme) => ({
  contactTitle: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  socialMediaIconContainer: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    '& div': {
      textAlign: 'center',
      '& svg': {
        fontSize: '4rem',
        color: '#f37937',
      },
    },
  },
}));

export default useContactSectionStyles;
