import { makeStyles } from "@material-ui/core/styles"

const useSideNavStyles = makeStyles(theme => ({
    sideNav: {
        width: '250px'
    },
    anchorLink: {
        color: "#333333",
        textDecoration: 'none'
    }
}))

export default useSideNavStyles;