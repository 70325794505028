import React from 'react';
import './App.css';
import { MuiThemeProvider } from '@material-ui/core/styles';
import {
  TopNav,
  AboutSection,
  PortfolioSection,
  ContactSection,
  FooterSection,
} from './components';
import theme from './theme/theme';


function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <>
        <TopNav />
        <AboutSection />
        <PortfolioSection />
        <ContactSection />
        <FooterSection />
      </>
    </MuiThemeProvider>
  );
}

export default App;
