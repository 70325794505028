import React from 'react';
import useFooterSectionStyles from './style';

const FooterSection = () => {
  const classes = useFooterSectionStyles();
  return (
    <section>
      <p className={classes.footer}>
                Design and Code by Vasil Pavlov ©
        {' '}
        {new Date().getFullYear()}
. All Rights Reserved
      </p>
    </section>
  );
};

export default FooterSection;
