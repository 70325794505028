import React from 'react';
import { Grid, Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import usePortfolioItemStyles from './style';


const PortfolioItem = ({ text = '', imageSrc = '', linkHref = '#' }) => {
  const classes = usePortfolioItemStyles();
  const clickHandler = (event, linkHref) => {
    event.preventDefault();
    linkHref !== '#' && window.open(linkHref, '_blank');
  };

  return (
    <Grid item xs={10} sm={6} md={6} lg={4}>
      <div className={classes.portfolioItem}>
        <Link href={linkHref} onClick={(event) => clickHandler(event, linkHref)}>
          <img src={imageSrc} className={classes.image} alt={text}/>
          <Typography className={classes.title}>
            {text}
          </Typography>
        </Link>
      </div>
    </Grid>
  );
};
export default PortfolioItem;
