import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import ImageIcon from '@material-ui/icons/Image';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import useSideNavStyles from './style';

const SideNav = ({ toggleDrawer }) => {
  const classes = useSideNavStyles();
  return (
    <List
      className={classes.sideNav}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}>
      <AnchorLink offset='128' className={classes.anchorLink} href='#about-section'>
        <ListItem button>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={'About'} />
        </ListItem>
      </AnchorLink>
      <AnchorLink offset='-100' className={classes.anchorLink} href='#portfolio-section'>
        <ListItem button>
          <ListItemIcon>
            <ImageIcon />
          </ListItemIcon>
          <ListItemText primary={'Portfolio'} />
        </ListItem>
      </AnchorLink>
      <AnchorLink offset='-100' className={classes.anchorLink} href='#contact-section'>
        <ListItem button>
          <ListItemIcon>
            <LinkedInIcon />
          </ListItemIcon>
          <ListItemText primary={'Contact'} />
        </ListItem>
      </AnchorLink>
    </List>
  )
}

export default SideNav